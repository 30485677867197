import { useMemo } from 'react';

import { monorepoRoutePaths } from '@apps/admin/route.paths';
import { DashboardTelemetry, useDashboardTelemetry } from '@apps/admin/routes/Dashboard/Dashboard.telemetry';
import { ProductVisibilityEnum } from '@apps/admin/routes/Dashboard/common/enums/ProductVisibilityEnum';
import { GetTranslation, RoutesContextProps, useRouterHelper, useTranslation } from '@shared/core';
import { Bell, CustomCard, Email, Emails, Invitation, PrintCard, SaveTheDate, ThankYou } from '@withjoy/joykit/icons';

import { ProductVerticals } from '@shared/core/productVerticals/productVerticals.types';
import { ProductRow } from '../../PrimaryDashboardContent/PrimaryDashboardContent.types';
import { ProductRowItemProps } from '../../PrimaryDashboardContent/ProductRowItem';
import { AdminDashboardV2Translations } from '@locales/generated';

const getCardTilesByCategory = (args: {
  routeHelpers: RoutesContextProps;
  t2: GetTranslation<AdminDashboardV2Translations<string>>;
  telemetry: DashboardTelemetry;
  printThankYouCategoryEnabled?: boolean;
}): ProductRowItemProps[] => {
  const { routeHelpers, t2, telemetry, printThankYouCategoryEnabled } = args;

  const { invites: invitesTranslations } = t2('sections');
  const invitesStrings = invitesTranslations;

  return [
    {
      title: invitesStrings.children.saveTheDates.title,
      icon: SaveTheDate,
      hideDropdownLabel: true,
      subItems: [
        {
          title: invitesStrings.children.saveTheDates.children.print.title,
          path: routeHelpers.buildPath('/cards?ctgy=saveTheDate'),
          pathIsInJoyWeb: true,
          onClick: (categoryArg?: string) => telemetry.openPage({ label: 'saveTheDates.paper', section: 'Messaging', categoryArg })
        },
        {
          // TODO: Perform in-app navigation when we push new ecard editing experience to users
          // https://withjoy.atlassian.net/browse/PRNT-843
          title: invitesStrings.children.saveTheDates.children.digital.title,
          path: routeHelpers.buildPath(monorepoRoutePaths.eCardSaveTheDate),
          onClick: (categoryArg?: string) => telemetry.openPage({ label: 'saveTheDates.digital', section: 'Messaging', categoryArg }),
          telemetryName: monorepoRoutePaths.eCardSaveTheDate
        }
      ]
    },
    {
      title: invitesStrings.children.invitations.title,
      icon: Invitation,
      hideDropdownLabel: true,
      subItems: [
        {
          title: invitesStrings.children.invitations.children.print.title,
          path: routeHelpers.buildPath('/cards?ctgy=invitation'),
          pathIsInJoyWeb: true,
          onClick: (categoryArg?: string) => telemetry.openPage({ label: 'invitations.paper', section: 'Messaging', categoryArg })
        },
        {
          // TODO: Perform in-app navigation when we push new ecard editing experience to users
          // https://withjoy.atlassian.net/browse/PRNT-843
          title: invitesStrings.children.invitations.children.digital.title,
          path: routeHelpers.buildPath(monorepoRoutePaths.eCardInvitations),
          onClick: (categoryArg?: string) => telemetry.openPage({ label: 'invitations.digital', section: 'Messaging', categoryArg }),
          telemetryName: monorepoRoutePaths.eCardInvitations
        }
      ]
    },
    ...(printThankYouCategoryEnabled
      ? [
          {
            title: invitesStrings.children.thankYou.title,
            icon: ThankYou,
            hideDropdownLabel: true,
            path: routeHelpers.buildPath('/cards?ctgy=thankYou'),
            pathIsInJoyWeb: true,
            onClick: (categoryArg?: string) => telemetry.openPage({ label: 'invitations.thankYou', section: 'Messaging', categoryArg })
          }
        ]
      : []),
    {
      title: invitesStrings.children.customCards.title,
      icon: CustomCard,
      path: routeHelpers.buildPath(monorepoRoutePaths.eCardCustom),
      onClick: (categoryArg?: string) => telemetry.openPage({ label: 'customCards', section: 'Messaging', categoryArg }),
      telemetryName: monorepoRoutePaths.eCardCustom
    }
  ];
};

const getLegacyCardTiles = (args: {
  newPrintExperienceEnabled: boolean;
  printThankYouCategoryEnabled: boolean;
  routeHelpers: RoutesContextProps;
  t2: GetTranslation<AdminDashboardV2Translations<string>>;
  telemetry: DashboardTelemetry;
}): ProductRowItemProps[] => {
  const { newPrintExperienceEnabled, printThankYouCategoryEnabled, t2, routeHelpers, telemetry } = args;
  const { invites: invitesTranslations } = t2('sections');
  const invitesStrings = invitesTranslations;

  return [
    ...(newPrintExperienceEnabled
      ? [
          {
            title: invitesStrings.children.print.title,
            icon: Invitation,
            hideDropdownLabel: true,
            subItems: [
              {
                title: invitesStrings.children.print.children.invitations.title,
                path: routeHelpers.buildPath('/cards?ctgy=invitation'),
                pathIsInJoyWeb: true,
                onClick: (categoryArg?: string) => telemetry.openPage({ label: 'paperCards.invitations', section: 'Messaging', categoryArg })
              },
              {
                title: invitesStrings.children.print.children.saveTheDates.title,
                path: routeHelpers.buildPath('/cards?ctgy=saveTheDate'),
                pathIsInJoyWeb: true,
                onClick: (categoryArg?: string) => telemetry.openPage({ label: 'paperCards.savethedate', section: 'Messaging', categoryArg })
              },
              ...(printThankYouCategoryEnabled
                ? [
                    {
                      title: invitesStrings.children.print.children.thankYou.title,
                      path: routeHelpers.buildPath('/cards?ctgy=thankYou'),
                      pathIsInJoyWeb: true,
                      onClick: (categoryArg?: string) => telemetry.openPage({ label: 'paperCards.thankyou', section: 'Messaging', categoryArg })
                    }
                  ]
                : [])
            ]
          }
        ]
      : []),
    {
      title: invitesStrings.children.digital.title,
      icon: PrintCard,
      hideDropdownLabel: true,
      subItems: [
        {
          title: invitesStrings.children.digital.children.invitations.title,
          path: routeHelpers.buildPath(monorepoRoutePaths.eCardInvitations),
          onClick: (categoryArg?: string) => telemetry.openPage({ label: 'digitalCards.invitations', section: 'Messaging', categoryArg }),
          telemetryName: monorepoRoutePaths.eCardInvitations
        },
        {
          title: invitesStrings.children.digital.children.saveTheDates.title,
          path: routeHelpers.buildPath(monorepoRoutePaths.eCardSaveTheDate),
          onClick: (categoryArg?: string) => telemetry.openPage({ label: 'digitalCards.savethedate', section: 'Messaging', categoryArg }),
          telemetryName: monorepoRoutePaths.eCardSaveTheDate
        },
        {
          title: invitesStrings.children.digital.children.customCard.title,
          path: routeHelpers.buildPath(monorepoRoutePaths.eCardCustom),
          onClick: (categoryArg?: string) => telemetry.openPage({ label: 'digitalCards.custom', section: 'Messaging', categoryArg }),
          telemetryName: monorepoRoutePaths.eCardCustom
        }
      ]
    }
  ];
};

export const useInvitationsProductRow = (
  productVerticals: ProductVerticals,
  newPrintExperienceEnabled: boolean,
  printThankYouCategoryEnabled: boolean,
  hotelBlockReminderEnabled: boolean,
  printAdminDashboardIconUpdateEnabled: boolean
): ProductRow => {
  const routeHelpers = useRouterHelper();
  const telemetry = useDashboardTelemetry();
  const { t2 } = useTranslation('adminDashboardV2');

  const invitationsProductRow = useMemo<ProductRow>(() => {
    const visibilityTranslations = t2('visibility');
    const { invites: invitesTranslations } = t2('sections');
    const invitesStrings = invitesTranslations;
    const visibilityStrings = visibilityTranslations;

    const emailTile: ProductRowItemProps = hotelBlockReminderEnabled
      ? {
          title: invitesStrings.children.messaging.title,
          icon: Email,
          hideDropdownLabel: true,
          subItems: [
            {
              title: invitesStrings.children.messaging.children.reminder.title,
              path: routeHelpers.buildPath(monorepoRoutePaths.emailCreate + '?draftType=reminder&fromDashboard=true'),
              onClick: (categoryArg?: string) => telemetry.openPage({ label: 'reminderemail', section: 'Messaging', categoryArg }),
              telemetryName: monorepoRoutePaths.emailCreate + '/reminderemail'
            },
            {
              title: invitesStrings.children.messaging.children.hotel.title,
              path: routeHelpers.buildPath('/hotel-reminder'),
              onClick: (categoryArg?: string) => telemetry.openPage({ label: 'hotelreminder', section: 'Messaging', categoryArg }),
              telemetryName: monorepoRoutePaths.emailCreate + '/hotelreminder'
            },
            {
              title: invitesStrings.children.messaging.children.general.title,
              path: routeHelpers.buildPath(monorepoRoutePaths.emailCreate + '?draftType=generic&fromDashboard=true'),
              onClick: (categoryArg?: string) => telemetry.openPage({ label: 'generalmessage', section: 'Messaging', categoryArg }),
              telemetryName: monorepoRoutePaths.emailCreate + '/generalmessage'
            }
          ]
        }
      : {
          title: invitesStrings.children.emails.title,
          icon: Emails,
          subItems: [
            {
              title: invitesStrings.children.emails.children.reminder.title,
              icon: Bell,
              path: routeHelpers.buildPath(monorepoRoutePaths.emailCreate + '?draftType=reminder&fromDashboard=true'),
              onClick: (categoryArg?: string) => telemetry.openPage({ label: 'reminderemail', section: 'Messaging', categoryArg }),
              telemetryName: monorepoRoutePaths.emailCreate + '/reminderemail'
            },
            {
              title: invitesStrings.children.emails.children.general.title,
              icon: Email,
              path: routeHelpers.buildPath(monorepoRoutePaths.emailCreate + '?draftType=generic&fromDashboard=true'),
              onClick: (categoryArg?: string) => telemetry.openPage({ label: 'generalmessage', section: 'Messaging', categoryArg }),
              telemetryName: monorepoRoutePaths.emailCreate + '/generalmessage'
            }
          ]
        };

    return {
      title: invitesStrings.title,
      stringOn: visibilityStrings.on,
      stringOff: visibilityStrings.off,
      isEnabled: !productVerticals.optOutOfInvites,
      visibility: ProductVisibilityEnum.Public,
      children: [
        ...(printAdminDashboardIconUpdateEnabled
          ? getCardTilesByCategory({ routeHelpers, t2, telemetry, printThankYouCategoryEnabled })
          : getLegacyCardTiles({ newPrintExperienceEnabled, printThankYouCategoryEnabled, routeHelpers, t2, telemetry })),
        emailTile
      ]
    };
  }, [
    t2,
    productVerticals.optOutOfInvites,
    printThankYouCategoryEnabled,
    newPrintExperienceEnabled,
    routeHelpers,
    hotelBlockReminderEnabled,
    telemetry,
    printAdminDashboardIconUpdateEnabled
  ]);

  return invitationsProductRow;
};
